import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "icon"];

  toggle(event) {
    const button = event.currentTarget;
    const content = button.nextElementSibling;
    const icon = button.querySelector('[data-accordion-target="icon"]');

    const isExpanded = content.classList.contains('expanded');

    if (isExpanded) {
      content.classList.remove('expanded');
      content.classList.add('collapsed');
      icon.classList.remove('rotate-180');
    } else {
      this.contentTargets.forEach((element) => {
        element.classList.remove('expanded');
        element.classList.add('collapsed');
      });
      this.iconTargets.forEach((iconElement) => {
        iconElement.classList.remove('rotate-180');
      });

      content.classList.remove('collapsed');
      content.classList.add('expanded');
      icon.classList.add('rotate-180');
    }
  }
}
