import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    open(event) {
        event.preventDefault();
        const overlay = document.getElementById('search-modal-overlay');
        const modal = document.getElementById('search-modal-container');
        overlay.classList.remove('hidden');
        modal.classList.remove('hidden');
        modal.style.display = 'flex';
    }

    close(event) {
        event.preventDefault();
        const modal = document.getElementById('search-modal-container');
        const overlay = document.getElementById('search-modal-overlay');
        modal.classList.add('hidden');
        overlay.classList.add('hidden');
        modal.style.display = 'none'; 
    }
}
