import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["gallery", "toggleButton", "photoSwipe"];

  connect() {
    this.items = Array.from(this.galleryTarget.querySelectorAll(".gallery-item")).map(
      (element) => {
        const size = element.getAttribute("data-size").split("x");
        return {
          src: element.getAttribute("href"),
          w: parseInt(size[0]),
          h: parseInt(size[1]),
          msrc: element.querySelector("img").getAttribute("src"),
          el: element,
        };
      }
    );

    this.galleryTarget.querySelectorAll(".gallery-item").forEach((item, index) => {
      item.addEventListener("click", (event) => this.openPhotoSwipe(event, index));
    });
  }

  toggle(event) {
    event.preventDefault();
    const isExpanded = this.toggleButtonTarget.textContent.trim() === "See less";

    if (isExpanded) {
      this.hideAllImages();
      this.toggleButtonTarget.textContent = "See all";
    } else {
      this.showAllImages();
      this.toggleButtonTarget.textContent = "See less";
    }
  }

  showAllImages() {
    const hiddenItems = this.galleryTarget.querySelectorAll(".gallery-item.hidden");
    hiddenItems.forEach((item) => item.classList.remove("hidden"));
  }

  hideAllImages() {
    const items = this.galleryTarget.querySelectorAll(".gallery-item");
    items.forEach((item, index) => {
      if (index >= 8) {
        item.classList.add("hidden");
      }
    });
  }

  openPhotoSwipe(event, index) {
    event.preventDefault();

    const options = {
      index: index, 
      bgOpacity: 0.8, 
      showHideOpacity: true, 
      history: false,
      loop: true, 
    };

    const pswpElement = this.photoSwipeTarget;

    const gallery = new window.PhotoSwipe(pswpElement, window.PhotoSwipeUI_Default, this.items, options);
    gallery.init();
  }
}
