import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["filterContainer", "toggleBtn"];

  connect() {
    this.idPrefix = this.element.dataset.prefix || 'default'; 

    if (this.idPrefix === 'amenities') {
      this.filters = [
        "Wi-fi", "Washer", "Amenity 1", "Amenity 2", "Amenity 3", "Amenity 4",
        "Amenity 5", "Amenity 6", "Amenity 7", "Amenity 8", "Amenity 9", "Amenity 10", "Amenity 11"
      ];
    } else if (this.idPrefix === 'property-type') {
      this.filters = [
        "Hotels X", "Hotels Y", "Hotels Z", "Hotels A", "Hotels B", "Hotels C",
        "Hotels D", "Hotels E", "Hotels F", "Hotels G", "Hotels H", "Hotels I"
      ];
    }

    this.checkedFilters = new Set(); 
    this.visibleFilters = 6;
    this.renderFilters();
  }

  toggleCheckbox(event) {
    const filterName = event.target.dataset.filterName;
    if (event.target.checked) {
      this.checkedFilters.add(filterName); 
    } else {
      this.checkedFilters.delete(filterName); 
    }
  }

  renderFilters() {
    this.filterContainerTarget.innerHTML = "";

    this.filters.forEach((filter, index) => {
      const uniqueId = `${this.idPrefix}-checkbox-${index}`; 
      const isChecked = this.checkedFilters.has(filter);

      if (index < this.visibleFilters) {
        const filterElement = `
          <div class="flex items-center gap-4">
              <input type="checkbox" class="checkbox custom-checkbox" id="${uniqueId}" data-filter-name="${filter}"
              ${isChecked ? "checked" : ""} data-action="change->show-more#toggleCheckbox" />
              <label for="${uniqueId}" class="text text-[#222] font-normal text-[14px] leading-[130%]">${filter}</label>
          </div>
        `;
        this.filterContainerTarget.insertAdjacentHTML('beforeend', filterElement);
      }
    });
  }

  toggleFilters() {
    if (this.visibleFilters === 6) {
      this.visibleFilters = this.filters.length;
      this.toggleBtnTarget.textContent = "Show less";
    } else {
      this.visibleFilters = 6;
      this.toggleBtnTarget.textContent = "Show more";
    }
    this.renderFilters();
  }
}
