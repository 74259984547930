import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["roomCount", "hiddenRooms"];

  connect() {
    this.roomCountTarget.textContent = 1; 
    this.hiddenRoomsTarget.value = 1;     
  }

  increaseRoomCount(event) {
    event.preventDefault();
    let currentCount = parseInt(this.roomCountTarget.textContent, 10); 
    currentCount += 1; 

    this.roomCountTarget.textContent = currentCount; 
    this.hiddenRoomsTarget.value = currentCount;     
  }

  decreaseRoomCount(event) {
    event.preventDefault();
    let currentCount = parseInt(this.roomCountTarget.textContent, 10);

    if (currentCount > 1) { 
      currentCount -= 1;
    }

    this.roomCountTarget.textContent = currentCount; 
    this.hiddenRoomsTarget.value = currentCount;     
  }
}
