import { Controller } from '@hotwired/stimulus';
import Flickity from 'flickity';
import imagesLoaded from 'imagesloaded';
import 'flickity/dist/flickity.css';

export default class extends Controller {
  static targets = ["gallery", "toggleButton", "photoSwipe"];

  connect() {
    this.initializePhotoSwipe();
    this.initializeCarousel();
  }

  initializePhotoSwipe() {
    this.items = Array.from(this.galleryTarget.querySelectorAll(".gallery-item")).map((element) => {
      const size = element.getAttribute("data-size").split("x");
      return {
        src: element.getAttribute("href"),
        w: parseInt(size[0]),
        h: parseInt(size[1]),
        msrc: element.querySelector("img").getAttribute("src"),
        el: element,
      };
    });

    this.galleryTarget.querySelectorAll(".gallery-item").forEach((item, index) => {
      item.addEventListener("click", (event) => this.openPhotoSwipe(event, index));
    });
  }

  openPhotoSwipe(event, index) {
    event.preventDefault();
    const options = {
      index: index,
      bgOpacity: 0.8,
      showHideOpacity: true,
      history: false,
    };
    const pswpElement = this.photoSwipeTarget;
    const gallery = new window.PhotoSwipe(pswpElement, window.PhotoSwipeUI_Default, this.items, options);
    gallery.init();
  }

  initializeCarousel() {
    const carousels = this.element.querySelectorAll('.carousel-explore');

    carousels.forEach(carousel => {
      const slideCount = carousel.querySelectorAll('.carousel-cell img').length;

      if (slideCount > 0) {
        imagesLoaded(carousel, () => {
          const flickityInstance = new Flickity(carousel, {
            cellAlign: 'center',
            contain: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: false,
            draggable: true, 
          });

          flickityInstance.on('ready', () => {
            flickityInstance.resize();
          });
        });
      }
    });
  }
}