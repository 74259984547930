import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggle", "modal", "countSpan", "hiddenAdults", "hiddenChildren", "hiddenPets", "guestCount"];

  connect() {
    this.updateGuestsCount();
    document.addEventListener("click", this.hideModal.bind(this));
  }

  disconnect() {
    document.removeEventListener("click", this.hideModal.bind(this));
  }

  toggle(event) {
    event.stopPropagation();
    this.modalTarget.classList.toggle("hidden");
  }

  hideModal(event) {
    if (!this.modalTarget.contains(event.target) && !this.toggleTarget.contains(event.target)) {
      this.modalTarget.classList.add("hidden");
    }
  }

  increaseGuestCount(event) {
    const countElement = event.currentTarget.nextElementSibling;
    const currentCount = parseInt(countElement.textContent);
    countElement.textContent = currentCount + 1;
    this.updateGuestsCount();
  }

  decreaseGuestCount(event) {
    const countElement = event.currentTarget.previousElementSibling;
    const currentCount = parseInt(countElement.textContent);
    if (currentCount > 0) {
      countElement.textContent = currentCount - 1;
      this.updateGuestsCount();
    }
  }

  updateGuestsCount() {
    const adultsCount = parseInt(this.guestCountTargets.find(target => target.dataset.guest === "adults").textContent);
    const childrenCount = parseInt(this.guestCountTargets.find(target => target.dataset.guest === "children").textContent);
    const petsCount = parseInt(this.guestCountTargets.find(target => target.dataset.guest === "pets").textContent);

    const totalGuests = adultsCount + childrenCount;
    
    if (totalGuests > 0) {
      this.countSpanTarget.textContent = `${totalGuests} Guests`;
    } else {
      this.countSpanTarget.textContent = `Guests`;
    }

    this.hiddenAdultsTarget.value = adultsCount;
    this.hiddenChildrenTarget.value = childrenCount;
    this.hiddenPetsTarget.value = petsCount;
  }
}
