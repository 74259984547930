import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "dobInput", 
  ] 

  initialize() {
    super.initialize();

    this.dobInputTarget.addEventListener('keydown', (e) => {
      let key;
      const regex = /[0-9]|\./;
      if (e.type === 'paste') {
        e.returnValue = false;
        if(e.preventDefault) e.preventDefault(); 
      } else {
          key = e.keyCode || e.which;
          key = String.fromCharCode(key);
      }
      if( e.keyCode !== 8) {
        if(!regex.test(key)) {
          e.returnValue = false;
          if(e.preventDefault) e.preventDefault();
        }
        if (this.dobInputTarget.value.length == 2 || this.dobInputTarget.value.length == 5) {
          this.dobInputTarget.value = this.dobInputTarget.value + '/';        
        }
        if (this.dobInputTarget.value.length == 10) {
          e.returnValue = false;
          if(e.preventDefault) e.preventDefault(); 
        }
      }
    })
  }
}
