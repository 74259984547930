import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["checkin", "checkout", "hiddenDatepicker", "checkinDisplay", "checkoutDisplay"];

  connect() {
    this.initDatepicker();
  }

  initDatepicker() {
    const datepickerElement = this.element;
    const hiddenInput = this.hiddenDatepickerTarget;
    const checkInSpan = this.checkinDisplayTarget;
    const checkOutSpan = this.checkoutDisplayTarget;

    const isMobile = window.innerWidth < 768;
    const showMonths = isMobile ? 1 : 2;

    const fp = flatpickr(datepickerElement, {
      mode: "range",
      minDate: "today",
      dateFormat: "Y-m-d",
      clickOpens: false,
      closeOnSelect: false, 
      showMonths: showMonths,
      monthSelectorType: "static",
      inline: false,
      appendTo: datepickerElement,
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["M", "T", "W", "T", "F", "S", "S"],
          longhand: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"],
        },
        months: {
          shorthand: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          longhand: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        },
      },
      onReady: (selectedDates, dateStr, instance) => {
        instance.calendarContainer.classList.add('calendar-exp');

        this.addAvailabilityDiv(instance);
      },
      onOpen: (selectedDates, dateStr, instance) => {
        instance.calendarContainer.classList.add('custom-calendar');

        const allMonthElements = instance.calendarContainer.querySelectorAll('.flatpickr-current-month');
        allMonthElements.forEach(monthElement => {
          monthElement.addEventListener('click', function(event) {
            event.stopPropagation();
          });
        });

        const allYearInputs = instance.calendarContainer.querySelectorAll('.cur-year');
        allYearInputs.forEach(yearInput => {
          yearInput.disabled = true;
        });

        const allPrevArrows = instance.calendarContainer.querySelectorAll(".flatpickr-prev-month");
        const allNextArrows = instance.calendarContainer.querySelectorAll(".flatpickr-next-month");

        allPrevArrows.forEach(prevArrow => {
          prevArrow.addEventListener('click', function(e) {
            e.stopPropagation();
            instance.changeMonth(-1);
          });
        });

        allNextArrows.forEach(nextArrow => {
          nextArrow.addEventListener('click', function(e) {
            e.stopPropagation();
            instance.changeMonth(1);
          });
        });

        if (!instance.calendarContainer.querySelector('.availability')) {
          this.addAvailabilityDiv(instance);
        }

        document.addEventListener('click', this.handleDocumentClick.bind(this, instance), true);
      },
      onClose: () => {
        const calendarContainer = datepickerElement.querySelector(".custom-calendar");
        if (calendarContainer) {
          calendarContainer.classList.remove('custom-calendar');
        }

        document.removeEventListener('click', this.handleDocumentClick, true);
      },
      onChange: (selectedDates) => {
        this.updateHiddenInput(selectedDates);
        this.updateDatePickerText(selectedDates);
        this.applyBorderRadius(fp);
      },
    });

    this.fp = fp; 

    this.checkinTarget.addEventListener("click", (event) => {
      event.stopPropagation();
      fp.open();
    });

    this.checkoutTarget.addEventListener("click", (event) => {
      event.stopPropagation();
      fp.open();
    });

    const form = datepickerElement.closest("form");
    if (form) {
      form.addEventListener("submit", () => {
        fp.close();
      });
    }
  }

  handleDocumentClick(instance, event) {
    if (
      !this.element.contains(event.target) &&
      !instance.calendarContainer.contains(event.target)
    ) {
      instance.close();
    }
  }

  addAvailabilityDiv(instance) {
    const availabilityDiv = document.createElement('div');
    availabilityDiv.className = 'availability';

    availabilityDiv.style.borderTop = '1px solid #e6e6e6';
    availabilityDiv.style.padding = '10px';
    availabilityDiv.style.textAlign = 'center';

    const checkButton = document.createElement('button');
    checkButton.type = 'button';
    checkButton.className = 'btn btn-primary';
    checkButton.textContent = 'Check Availability';

    checkButton.addEventListener('click', () => {
      if (this.hiddenDatepickerTarget.value === "") {
        alert("Choose the date first");
      } else {
        const form = this.element.closest('form');
        if (form) {
          form.submit();
        }
        instance.close();
      }
    });

    availabilityDiv.appendChild(checkButton);

    instance.calendarContainer.appendChild(availabilityDiv);
  }

  updateHiddenInput(selectedDates) {
    if (selectedDates.length === 2) {
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2); 
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
      };
      const startDate = formatDate(selectedDates[0]);
      const endDate = formatDate(selectedDates[1]);
      this.hiddenDatepickerTarget.value = `${startDate} to ${endDate}`;
    } else {
      this.hiddenDatepickerTarget.value = '';
    }
  }

  updateDatePickerText(selectedDates) {
    if (selectedDates.length >= 1) {
      const options = { month: "short", day: "numeric" };
      const checkInDate = selectedDates[0].toLocaleDateString("en-US", options);
      this.checkinDisplayTarget.textContent = checkInDate;
    } else {
      this.checkinDisplayTarget.textContent = "Add Dates";
    }

    if (selectedDates.length === 2) {
      const options = { month: "short", day: "numeric" };
      const checkOutDate = selectedDates[1].toLocaleDateString("en-US", options);
      this.checkoutDisplayTarget.textContent = checkOutDate;
    } else {
      this.checkoutDisplayTarget.textContent = "Add Dates";
    }
  }

  applyBorderRadius(instance) {
    const days = instance.calendarContainer.querySelectorAll('.flatpickr-day');
    days.forEach(day => {
      day.classList.remove('startOfWeek', 'endOfWeek');

      if (day.classList.contains('inRange')) {
        const dayNumber = day.dateObj.getDay();
        if (dayNumber === 1) { // Ponedeljak
          day.classList.add('startOfWeek');
        } else if (dayNumber === 0) {
          day.classList.add('endOfWeek');
        }
      }
    });
  }
}
