
import '../css/index.css';

import 'htmx.org';
import { Application } from "@hotwired/stimulus";

import AccordingController from "./controllers/accordion_controller";
import CarouselExploreController from "./controllers/carousel_explore_controller";
import CarouselOwlController from "./controllers/carousel_owl_controller";
import CarouselPropertiesController from "./controllers/carousel_properties_controller";
import ClearAllFiltersMobController from "./controllers/clear_all_filters_mob_controller";
import DatepickerController from "./controllers/datepicker_controller";
import DatepickerControllerSingleEx from "./controllers/datepicker_controller_single_ex";
import DobFieldController from "./controllers/dob_field_controller";
import DropDownController from "./controllers/dropdown_controller";
import FilterModalController from "./controllers/filter_modal_controller";
import GalleryController from "./controllers/gallery_controller";
import GuestsController from "./controllers/guests_controller";
import GuestsControllerExperience from "./controllers/guests_controller_experience";
import MenuController from "./controllers/menu_controller";
import NavModalController from "./controllers/nav_modal_controller";
import PredictiveSearchController from "./controllers/predictive_search_controller";
import PriceModalController from "./controllers/price_modal_controller";
import PriceSliderController from "./controllers/price_slider_controller";
import RatingController from "./controllers/rating_controller";
import RoomCountController from "./controllers/room_count_controller";
import ShowMoreController from "./controllers/show_more_controller";
import TabController from "./controllers/tab_controller";
import TosNavigation from "./controllers/tos_navigation";
import UserGalleryController from "./controllers/user_gallery_controller";


export let ready = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
}

/* */

ready(() => {
    window.Stimulus = Application.start()
    Stimulus.register("dobfield", DobFieldController);
    Stimulus.register("dropdown", DropDownController);
    Stimulus.register("carousel-explore", CarouselExploreController);
    Stimulus.register("predictive-search", PredictiveSearchController);
    Stimulus.register("datepicker", DatepickerController);
    Stimulus.register("data-picker-single-ex", DatepickerControllerSingleEx);
    Stimulus.register("guests", GuestsController);
    Stimulus.register("menu", MenuController);
    Stimulus.register("modal", NavModalController); 
    Stimulus.register("filter-modal", FilterModalController);
    Stimulus.register("price-slider", PriceSliderController);
    Stimulus.register("room-count", RoomCountController);
    Stimulus.register("show-more", ShowMoreController);
    Stimulus.register("clear-all-filters-mob", ClearAllFiltersMobController);
    Stimulus.register("rating", RatingController);
    Stimulus.register("guests-experience", GuestsControllerExperience);
    Stimulus.register("price-modal", PriceModalController);
    Stimulus.register("tab", TabController);
    Stimulus.register("accordion", AccordingController);
    Stimulus.register("gallery", GalleryController);
    Stimulus.register("user-images", UserGalleryController);
    Stimulus.register("carousel-owl", CarouselOwlController);
    Stimulus.register("carousel-properties", CarouselPropertiesController);
    Stimulus.register("scroll-spy", TosNavigation);
});
