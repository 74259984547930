import { Controller } from "@hotwired/stimulus";
import noUiSlider from 'nouislider';

export default class extends Controller {
  static targets = ["slider", "minPrice", "maxPrice"];

  connect() {
    noUiSlider.create(this.sliderTarget, {
      start: [0, 500],  
      connect: true,   
      range: {
        'min': 0,
        'max': 2000
      }
    });

    const handles = this.sliderTarget.querySelectorAll('.noUi-handle');
    handles.forEach(handle => {
      handle.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="49" viewBox="0 0 48 49" fill="none">
          <g filter="url(#filter0_d_562_8445)">
            <circle cx="23" cy="22.5" r="10" fill="none" stroke="#020D19" stroke-width="2"/>
            <circle cx="23" cy="22.5" r="8" fill="#020D19"/>
            <circle cx="23" cy="22.5" r="6.5" stroke="#FFFEFD" stroke-width="3"/>
          </g>
          <defs>
            <filter id="filter0_d_562_8445" x="0" y="0.5" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
              <feOffset dx="1" dy="2"/>
              <feGaussianBlur stdDeviation="8"/>
              <feComposite in2="hardAlpha" operator="out"/>
              <feColorMatrix type="matrix" values="0 0 0 0 0.00784314 0 0 0 0 0.0509804 0 0 0 0 0.0980392 0 0 0 0.1 0"/>
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_562_8445"/>
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_562_8445" result="shape"/>
            </filter>
          </defs>
        </svg>
      `;
    });

     this.sliderTarget.noUiSlider.on('update', (values, handle) => {
        if (handle === 0) {
          this.minPriceTarget.value = `$${Math.round(values[0])}`; 
        } else {
          this.maxPriceTarget.value = `$${Math.round(values[1])}`; 
        }
      });
  
      this.minPriceTarget.addEventListener('change', this.updateSliderFromInput.bind(this));
      this.maxPriceTarget.addEventListener('change', this.updateSliderFromInput.bind(this));
    }
  
    updateSliderFromInput() {
      const minValue = parseInt(this.minPriceTarget.value.replace('$', ''), 10) || 0;
      const maxValue = parseInt(this.maxPriceTarget.value.replace('$', ''), 10) || 2000;
  
      this.sliderTarget.noUiSlider.set([minValue, maxValue]);
    }
  }
