import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "toggle",
    "modal",
    "countSpanExperience",
    "hiddenAdultsExperience",
    "hiddenChildrenExperience",
    "hiddenPetsExperience",
    "guestCountExperience",
    "submitButton"
  ];

  connect() {
    this.updateGuestsCountExperience();
    this.boundHideModalExperience = this.hideModalExperience.bind(this);
    document.addEventListener("click", this.boundHideModalExperience);
  }

  disconnect() {
    document.removeEventListener("click", this.boundHideModalExperience);
  }

  toggle(event) {
    event.stopPropagation();
    this.modalTarget.classList.toggle("hidden");
  }

  hideModalExperience(event) {
    if (
      (!this.modalTarget.contains(event.target) && !this.toggleTarget.contains(event.target)) ||
      event.target === this.submitButtonTarget
    ) {
      this.modalTarget.classList.add("hidden");
    }
  }

  increaseGuestCount(event) {
    event.stopPropagation();
    const countElement = event.currentTarget.nextElementSibling;
    const currentCount = parseInt(countElement.textContent);
    countElement.textContent = currentCount + 1;
    this.updateGuestsCountExperience();
  }

  decreaseGuestCount(event) {
    event.stopPropagation(); 
    const countElement = event.currentTarget.previousElementSibling;
    const currentCount = parseInt(countElement.textContent);
    if (currentCount > 0) {
      countElement.textContent = currentCount - 1;
      this.updateGuestsCountExperience();
    }
  }

  updateGuestsCountExperience() {
    const adultsElement = this.guestCountExperienceTargets.find(
      (target) => target.dataset.guest === "adults"
    );
    const childrenElement = this.guestCountExperienceTargets.find(
      (target) => target.dataset.guest === "children"
    );

    const adultsCount = adultsElement ? parseInt(adultsElement.textContent) : 0;
    const childrenCount = childrenElement ? parseInt(childrenElement.textContent) : 0;

    let guestsText = "Number of guests";

    if (adultsCount > 0 || childrenCount > 0) {
      guestsText = `${adultsCount} adult${adultsCount > 1 ? "s" : ""}`;
      if (childrenCount > 0) {
        guestsText += `, ${childrenCount} child${childrenCount > 1 ? "ren" : ""}`;
      }
    }

    this.countSpanExperienceTarget.textContent = guestsText;

    this.hiddenAdultsExperienceTarget.value = adultsCount;
    this.hiddenChildrenExperienceTarget.value = childrenCount;
  }
}
