import { Controller } from '@hotwired/stimulus';
import raterJs from 'rater-js';

export default class extends Controller {
  connect() {
    this.initializeRating();
  }

  initializeRating() {
    const averageRating = parseFloat(this.element.dataset.averageRating); 

    raterJs({
      element: this.element,
      max: 5,
      rating: averageRating, 
      readOnly: true,
      starSize: 16,        
      step: 0.5,
      ratedFill: "#f5a623",
      unfilledColor: "#ccc"
    });
  }
}
