import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'predictiveResults',
        'destinationInput',
        'results',
        'noResults',
    ];

    connect() {
        this.hideNoResultsTimeout = null; 
        this.initializeEventListeners();
    }

    initializeEventListeners() {
        this.element.addEventListener('htmx:afterOnLoad', this.handleAfterOnLoad.bind(this));
        this.element.addEventListener('htmx:afterSwap', this.handleAfterSwap.bind(this));
    }

    handleAfterOnLoad(event) {
        const resultsDiv = this.predictiveResultsTarget;
        const destinationInput = this.destinationInputTarget;

        if (event.detail.target.id !== 'predictive-results' || !resultsDiv) return;

        if (destinationInput.value === "") {
            resultsDiv.classList.add('hidden');
        } else {
            this.toggleResultsVisibility();
        }
    }

    toggleResultsVisibility() {
        const resultsDiv = this.predictiveResultsTarget;
        const noResultsElement = document.getElementById('no-results');

        if (noResultsElement) {
            this.hideNoResultsTimeout = setTimeout(() => {
                resultsDiv.classList.add('hidden');
            }, 3000);
        } else {
            clearTimeout(this.hideNoResultsTimeout);
            resultsDiv.classList.remove('hidden');
        }
    }

    handleAfterSwap(event) {
        if (event.detail.target.id === 'predictive-results') {
            this.addClickListenersToResults();
        }
    }

    addClickListenersToResults() {
        const resultItems = this.resultsTargets;
        const destinationInput = this.destinationInputTarget;
        const resultsDiv = this.predictiveResultsTarget;

        resultItems.forEach(item => {
            item.addEventListener('click', () => {
                destinationInput.value = item.getAttribute('data-value');
                resultsDiv.classList.add('hidden');
            });
        });
    }
}

