import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "dropdownInput", 
    "dropdownOptions",
    "selectedOptionDisplay"
  ] 

  initialize() {
    super.initialize();
  }

  connect() {
    this.selectedValue = this.dropdownInputTarget.value;
    this.selectedValueDisplay = null;
    const listItems = this.dropdownOptionsTarget.children;
    [...listItems].forEach((li) => {
      if (li.dataset.value === this.selectedValue) {
        this.selectedValueDisplay = li.firstElementChild.innerHTML;
      }
    });

    if (this.selectedValue) {
        this._setSelectedValue();
    }
  }

  _setSelectedValue() {
    this.dropdownInputTarget.value = this.selectedValue;
    this.selectedOptionDisplayTarget.innerHTML = this.selectedValueDisplay;

    const listItems = this.dropdownOptionsTarget.children;
    [...listItems].forEach((li) => {
        const checkmark = li.querySelector(".dropdown-checkmark");
        if (li.dataset.value === this.selectedValue) {
            li.classList.remove("font-normal");
            li.classList.add("font-bold");
            checkmark.classList.remove("hidden");
            checkmark.classList.add("flex");
        } else {
            li.classList.remove("font-bold");
            li.classList.add("font-normal");
            checkmark.classList.remove("flex");
            checkmark.classList.add("hidden");
        }
    });
  }

  toggleState(e) {
    e.preventDefault();

    const showClasses = [
        "block",
        "opacity-100"
    ]
    const hideClasses = [
        "hidden",
        "opacity-0"
    ]
    if (this.dropdownOptionsTarget.classList.contains(hideClasses[0])) {
        for (let i = 0; i < hideClasses.length; i++) {
            this.dropdownOptionsTarget.classList.remove(hideClasses[i]);
        }
        for (let i = 0; i < showClasses.length; i++) {
            this.dropdownOptionsTarget.classList.add(showClasses[i]);
        }  
    } else {
        for (let i = 0; i < showClasses.length; i++) {
            this.dropdownOptionsTarget.classList.remove(showClasses[i]);
        }
        for (let i = 0; i < hideClasses.length; i++) {
            this.dropdownOptionsTarget.classList.add(hideClasses[i]);
        }
    }
  }

  optionSelected(e) {
    e.preventDefault();
    const selectedOption = e.currentTarget;
    this.selectedValue = selectedOption.dataset.value;
    this.selectedValueDisplay = selectedOption.firstElementChild.innerHTML;
    this._setSelectedValue();
    this.toggleState(e);

    const changeEvent = new Event("dropdownInputChange");
    this.dropdownInputTarget.dispatchEvent(changeEvent);
  }
}
