/*
Base StimulusReflex application controller

https://docs.stimulusreflex.com/rtfm/patterns

*/
import { Controller } from "@hotwired/stimulus"

import { debug } from "../utils/logger";

export default class extends Controller {
  initialize() {
    /*
        Creates a document-scoped variable with the same name as the controller.
        Allowing other controllers to call methods on this controller like so:

        element.controllerName.method()
    */
    debug(`Controller "${this.identifier}" initialized.`);
    this.element[this.identifier] = this;
  }

  connect() {}

  disconnect() {}
}
