import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  clearAll() {

    const checkIn = document.getElementById("check-in");
    const checkOut = document.getElementById("check-out");
    const hiddenDatepicker = document.getElementById("hidden-datepicker");

    if (checkIn && checkOut && hiddenDatepicker) {
      checkIn.textContent = "Check In";
      checkOut.textContent = "Check Out";
      hiddenDatepicker.value = ""; 
    }

    const guestCountSpans = document.querySelectorAll("[data-guests-target='guestCount']");
    const hiddenAdults = document.getElementById("hidden-adults");
    const hiddenChildren = document.getElementById("hidden-children");
    const hiddenPets = document.getElementById("hidden-pets");

    if (guestCountSpans) {
      guestCountSpans.forEach(span => {
        span.textContent = "0"; 
      });
    }
    if (hiddenAdults) hiddenAdults.value = "0";
    if (hiddenChildren) hiddenChildren.value = "0";
    if (hiddenPets) hiddenPets.value = "0";

    const priceSlider = document.getElementById("slider");
    const minPriceInput = document.getElementById("min-price");
    const maxPriceInput = document.getElementById("max-price");

    if (priceSlider && priceSlider.noUiSlider) {
      priceSlider.noUiSlider.set([0, 500]); 
    }
    if (minPriceInput) minPriceInput.value = "0";  
    if (maxPriceInput) maxPriceInput.value = "500";  

    const checkboxes = document.querySelectorAll("input[type='checkbox']");
    checkboxes.forEach(checkbox => {
      checkbox.checked = false; 
    });

    const showMoreControllers = document.querySelectorAll("[data-controller='show-more']");

    showMoreControllers.forEach((element) => {
      const showMoreController = window.Stimulus.getControllerForElementAndIdentifier(
        element,
        "show-more"
      );
      if (showMoreController && showMoreController.checkedFilters) {
        showMoreController.checkedFilters.clear();
      }
    });

    const roomCountSpan = document.querySelector("[data-room-count-target='roomCount']");
    const hiddenRoomsInput = document.querySelector("[data-room-count-target='hiddenRooms']");

    if (roomCountSpan) roomCountSpan.textContent = "1"; 
    if (hiddenRoomsInput) hiddenRoomsInput.value = "1";

  }
}
