import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    open(event) {
        event.preventDefault();
        document.querySelector('main').style.display = 'none';
        const modal = document.getElementById('filter-modal-container');
        if (modal) {
            modal.classList.remove('hidden');
            modal.style.display = 'flex'; 
        }
    }

    close(event) {
        event.preventDefault();
        document.querySelector('main').style.display = ''; 
        const modal = document.getElementById('filter-modal-container');
        if (modal) {
            modal.classList.add('hidden');
            modal.style.display = 'none'; 
        }
    }
}
