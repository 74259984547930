import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["row", "content"]

  connect() {
    this.startAnimation()
  }

  startAnimation() {
    this.rowTargets.forEach((row, index) => {
      const direction = row.dataset.direction
      const innerWrapper = row.querySelector(':scope > .flex') 
      const content = row.querySelector(':scope [data-carousel-owl-target="content"]')

      this.duplicateContent(content, row)

      if (direction === "left") {
        innerWrapper.classList.add("scroll-left")
      } else if (direction === "right") {
        innerWrapper.classList.add("scroll-right")
      }
    })
  }

  duplicateContent(content, row) {
    const contentWidth = content.offsetWidth
    const rowWidth = row.offsetWidth

    let duplicatesNeeded = Math.ceil((rowWidth * 3) / contentWidth)

    const MAX_DUPLICATES = 10
    duplicatesNeeded = Math.min(duplicatesNeeded, MAX_DUPLICATES)

    for (let i = 0; i < duplicatesNeeded; i++) {
      const clone = content.cloneNode(true)
      content.parentNode.appendChild(clone)
    }
  }
}
