import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tabBtn", "tabContent"]

  connect() {
    this.showActiveTab(); 
  }

  showActiveTab() {
    this.tabBtnTargets.forEach((button, index) => {
      const contentName = button.getAttribute('data-content');
      const contentElement = this.tabContentTargets.find(
        content => content.getAttribute('data-content') === contentName
      );

      if (index === 0) {
        button.classList.add("active");
        if (contentElement) contentElement.classList.remove("hidden");
      } else {
        button.classList.remove("active");
        if (contentElement) contentElement.classList.add("hidden");
      }
    });
  }

  show(event) {
    event.preventDefault();
    const clickedTab = event.currentTarget;
    const contentToShow = clickedTab.getAttribute('data-content');

    this.tabBtnTargets.forEach(button => {
      button.classList.remove("active");
    });
    this.tabContentTargets.forEach(content => {
      content.classList.add("hidden");
    });

    clickedTab.classList.add("active");
    const contentElement = this.tabContentTargets.find(
      content => content.getAttribute('data-content') === contentToShow
    );
    if (contentElement) contentElement.classList.remove("hidden");
  }
}
