import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image"];

  connect() {
    this.items = Array.from(this.imageTargets).map((element) => {
      const size = element.getAttribute("data-size").split("x");
      return {
        src: element.getAttribute("href"),
        w: parseInt(size[0]),
        h: parseInt(size[1]),
        msrc: element.querySelector("img").getAttribute("src"),
        el: element,
      };
    });

    this.imageTargets.forEach((item, index) => {
      item.addEventListener("click", (event) => this.openPhotoSwipe(event, index));
    });
  }

  openPhotoSwipe(event, index) {
    event.preventDefault();

    const pswpElement = document.querySelector(".pswp");

    if (!pswpElement) {
      console.error("PhotoSwipe target element is missing.");
      return;
    }

    const options = {
      index: index,
      bgOpacity: 0.8,
      showHideOpacity: false,
      history: false,
      loop: true,
    };

    const gallery = new window.PhotoSwipe(pswpElement, window.PhotoSwipeUI_Default, this.items, options);
    gallery.init();
  }
}
