import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["carousel"];

    connect() {
        this.cardWidth = 302; 
        this.gap = 24; 
        this.scrollPosition = 0;

        const cardElement = this.carouselTarget.querySelector('a');
        this.cardWidth = cardElement.offsetWidth;

        const computedStyle = getComputedStyle(this.carouselTarget);
        this.gap = parseInt(computedStyle.columnGap || computedStyle.gap || 0);

        this.prevButton = document.querySelector('[data-carousel-properties-target="prevButton"]');
        this.nextButton = document.querySelector('[data-carousel-properties-target="nextButton"]');

        this.prevButton.addEventListener("click", this.prev.bind(this));
        this.nextButton.addEventListener("click", this.next.bind(this));

        this.updateVisibleCards();
        this.updateButtons();
        this.updateButtonVisibility(); 
        window.addEventListener("resize", this.updateVisibleCards.bind(this));
        window.addEventListener("resize", this.updateButtonVisibility.bind(this)); 
    }

    disconnect() {
        this.prevButton.removeEventListener("click", this.prev.bind(this));
        this.nextButton.removeEventListener("click", this.next.bind(this));
        window.removeEventListener("resize", this.updateVisibleCards.bind(this));
        window.removeEventListener("resize", this.updateButtonVisibility.bind(this));
    }

    next() {
        const maxScroll = this.getMaxScroll();
        const scrollAmount = this.getScrollAmount();

        this.scrollPosition = Math.min(this.scrollPosition + scrollAmount, maxScroll);
        this.updateCarouselPosition();
        this.updateButtons();
    }

    prev() {
        const scrollAmount = this.getScrollAmount();

        this.scrollPosition = Math.max(this.scrollPosition - scrollAmount, 0);
        this.updateCarouselPosition();
        this.updateButtons();
    }

    updateCarouselPosition() {
        this.carouselTarget.style.transform = `translateX(-${this.scrollPosition}px)`;
    }

    updateButtons() {
        const maxScroll = this.getMaxScroll();

        const isDesktop = window.innerWidth >= 1024;

        if (this.scrollPosition === 0 || !isDesktop) {
            this.prevButton.classList.add("hidden");
            this.prevButton.classList.remove("block");
        } else {
            this.prevButton.classList.remove("hidden");
            this.prevButton.classList.add("block");
        }

        if (this.scrollPosition >= maxScroll || !isDesktop) {
            this.nextButton.classList.add("hidden");
            this.nextButton.classList.remove("block");
        } else {
            this.nextButton.classList.remove("hidden");
            this.nextButton.classList.add("block");
        }
    }

    updateButtonVisibility() {
        const screenWidth = window.innerWidth;

        if (screenWidth < 1024) {
            this.prevButton.classList.add("hidden");
            this.nextButton.classList.add("hidden");
        } else {
            this.updateButtons(); 
        }
    }

    getMaxScroll() {
        const totalWidth = this.carouselTarget.scrollWidth;
        const viewportWidth = this.carouselTarget.parentElement.clientWidth;

        return Math.max(totalWidth - viewportWidth, 0);
    }

    getScrollAmount() {
        const viewportWidth = this.carouselTarget.parentElement.clientWidth;
        const visibleCards = Math.floor(viewportWidth / (this.cardWidth + this.gap));

        return visibleCards * (this.cardWidth + this.gap);
    }

    updateVisibleCards() {
        const screenWidth = window.innerWidth;

        if (screenWidth < 640) {
            this.cardWidth = 200;
            this.gap = 12; 
            this.carouselTarget.style.overflowX = "auto"; 
        } else if (screenWidth < 1024) {
            this.cardWidth = 200;
            this.gap = 12;
            this.carouselTarget.style.overflowX = "hidden";
        } else {
            this.cardWidth = 302; 
            this.gap = 24;
            this.carouselTarget.style.overflowX = "hidden";
        }

        this.scrollPosition = 0;
        this.updateCarouselPosition();
        this.updateButtons();
    }
}
