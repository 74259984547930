import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
  static targets = ["datepicker", "hiddenDatepicker"];

  connect() {
    this.initDatepicker();
  }

  initDatepicker() {
    const datepickerElement = this.datepickerTarget;
    const hiddenInput = this.hiddenDatepickerTarget;

    const isMobile = window.innerWidth < 768; 
    const showMonths = isMobile ? 1 : 2; 

    const desktopSpan = document.getElementById('desktop-checkin-checkout'); 

    const checkInSpan = document.getElementById('check-in');
    const checkOutSpan = document.getElementById('check-out');

    const fp = flatpickr(datepickerElement, {
      mode: "range",
      minDate: "today",
      dateFormat: "Y-m-d",
      clickOpens: false,
      showMonths: showMonths,
      monthSelectorType: "static", 
      locale: {
        firstDayOfWeek: 1,
        weekdays: {
          shorthand: ["S", "M", "T", "W", "T", "F", "S"],
          longhand: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        },
        months: {
          shorthand: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          longhand: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        }
      },
      inline: false,
      appendTo: datepickerElement,
      onOpen: function(selectedDates, dateStr, instance) {
        instance.calendarContainer.classList.add('custom-calendar');
    
        const allMonthElements = instance.calendarContainer.querySelectorAll('.flatpickr-current-month');
        
        allMonthElements.forEach(monthElement => {
            monthElement.addEventListener('click', function(event) {
                event.stopPropagation(); 
            });
        });
    
        const allYearInputs = instance.calendarContainer.querySelectorAll('.cur-year');
        
        allYearInputs.forEach(yearInput => {
            yearInput.addEventListener('click', function(event) {
                event.stopPropagation(); 
            });
        });
    
        allYearInputs.forEach(input => {
          input.disabled = true; 
        });
    
        const allPrevArrows = instance.calendarContainer.querySelectorAll(".flatpickr-prev-month");
        const allNextArrows = instance.calendarContainer.querySelectorAll(".flatpickr-next-month");
    
        allPrevArrows.forEach(prevArrow => {
            prevArrow.addEventListener('click', function(e) {
                e.stopPropagation(); 
                instance.changeMonth(-1);
            });
        });
    
        allNextArrows.forEach(nextArrow => {
            nextArrow.addEventListener('click', function(e) {
                e.stopPropagation(); 
                instance.changeMonth(1);
            });
        });
    
      },
      onClose: function() {
        const calendarContainer = datepickerElement.querySelector(".custom-calendar");
        if (calendarContainer) {
          calendarContainer.classList.remove('custom-calendar');
        }
      },
      onChange: function(selectedDates) {
        applyBorderRadius(fp);
        updateHiddenInput(selectedDates);
        updateDatePickerText(selectedDates);
      }
    });

    datepickerElement.addEventListener('click', function(event) {
      event.stopPropagation();
      if (fp.isOpen) {
        fp.close();
      } else {
        fp.open();
      }
    });

    const form = datepickerElement.closest('form');
    if (form) {
      form.addEventListener('submit', function() {
        fp.close();
      });
    }

    function applyBorderRadius(instance) {
      const days = instance.calendarContainer.querySelectorAll('.flatpickr-day');
      days.forEach(day => {
        day.classList.remove('startOfWeek', 'endOfWeek');

        if (day.classList.contains('inRange')) {
          const dayNumber = day.dateObj.getDay();
          if (dayNumber === 1) {
            day.classList.add('startOfWeek');
          } else if (dayNumber === 0) {
            day.classList.add('endOfWeek');
          }
        }
      });
    }

    function updateHiddenInput(selectedDates) {
      if (selectedDates.length === 2) {
        const startDate = new Date(selectedDates[0].getTime() - (selectedDates[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        const endDate = new Date(selectedDates[1].getTime() - (selectedDates[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
        hiddenInput.value = `${startDate} to ${endDate}`;
      } else {
        hiddenInput.value = '';
      }
    }

    function updateDatePickerText(selectedDates) {
      if (isMobile) {
        if (selectedDates.length === 2) {
          const checkInDate = selectedDates[0].toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          const checkOutDate = selectedDates[1].toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          checkInSpan.textContent = checkInDate;
          checkOutSpan.textContent = checkOutDate;
        } else {
          checkInSpan.textContent = 'Check In';
          checkOutSpan.textContent = 'Check Out';
        }
      } else {
        if (selectedDates.length === 2) {
          const startDate = selectedDates[0].toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          const endDate = selectedDates[1].toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
          desktopSpan.textContent = `${startDate} — ${endDate}`;
        } else {
          desktopSpan.textContent = 'Check In — Out';
        }
      }
    }
  }
}
