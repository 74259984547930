import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal"];

  showModal(event) {
    event.preventDefault();
    event.stopPropagation(); 
    this.modalTarget.classList.remove("hidden");

    this.boundDocumentClickListener = this.documentClickListener.bind(this);
    document.addEventListener("click", this.boundDocumentClickListener);
  }

  hideModal() {
    this.modalTarget.classList.add("hidden");

    if (this.boundDocumentClickListener) {
      document.removeEventListener("click", this.boundDocumentClickListener);
      this.boundDocumentClickListener = null;
    }
  }

  documentClickListener(event) {
    if (!this.modalTarget.contains(event.target)) {
      this.hideModal();
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
