import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "section"];

  connect() {
    this.setActiveLink(); 
    window.addEventListener("scroll", this.setActiveLink.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.setActiveLink.bind(this));
  }

  setActiveLink() {
    let currentIndex = this.sectionTargets.length;

    while (--currentIndex && window.scrollY + 50 < this.sectionTargets[currentIndex].offsetTop) {}

    this.linkTargets.forEach((link) => link.classList.remove("menu-active"));

    if (this.linkTargets[currentIndex]) {
      this.linkTargets[currentIndex].classList.add("menu-active");
    }
  }
}
